"use client";

import React, { useRef } from "react";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import dynamic from "next/dynamic";
import Arrow from "../images/Arrow";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CloseIcon from "../images/CloseIcon";
import { useMediaQuery } from "usehooks-ts";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

function Flipbook({ pdfLink }: { pdfLink: string }) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const flipbookRef = useRef<any>(null);
  const handle = useFullScreenHandle();
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function handleGoNext() {
    flipbookRef?.current?.pageFlip()?.flipNext();
    setPageNumber(pageNumber + 2);
  }

  function handleGoPrev() {
    if (pageNumber === 0) return;
    flipbookRef?.current?.pageFlip()?.flipPrev();
    setPageNumber(pageNumber - 2);
  }

  if (typeof window === "undefined") return;

  if (isMobile) {
    return (
      <div className="w-full flex flex-col items-center">
        <FullScreen handle={handle}>
          <div className="bg-white h-full w-full flex items-center justify-center max-w-full gap-3 px-2 min-h-[507px]">
            {handle.active && (
              <div
                onClick={handle.exit}
                className="[&_path]:stroke-brand-beige-300 absolute top-16 right-16 z-50"
              >
                <CloseIcon />
              </div>
            )}
            <div onClick={handleGoPrev}>
              <Arrow />
            </div>
            <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={280}
                height={500}
              />
            </Document>
            <div onClick={handleGoNext} className="rotate-180">
              <Arrow />
            </div>
          </div>
        </FullScreen>
        <button
          onClick={handle.enter}
          className="text-brand-beige-300 font-martel"
        >
          Ver más
        </button>
      </div>
    );
  }

  return (
    <div className="w-full flex items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center gap-4">
        <FullScreen handle={handle}>
          <div className="flex items-center justify-center gap-4 min-w-[1000px] w-full h-full bg-white relative">
            {handle.active && (
              <div
                onClick={handle.exit}
                className="[&_path]:stroke-brand-beige-300 absolute top-16 right-16 z-50"
              >
                <CloseIcon />
              </div>
            )}

            <div onClick={handleGoPrev}>
              <Arrow />
            </div>
            {!handle.active && (
              <Document
                onError={console.log}
                file={pdfLink}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <div className="flex">
                  <div className="min-w-[400px]" onClick={handleGoPrev}>
                    {pageNumber === 0 ? (
                      <div className="bg-[#162136] w-[400px] h-[566px]"></div>
                    ) : (
                      <Page
                        pageNumber={pageNumber}
                        width={400}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        loading={() => (
                          <div className="bg-[#162136] min-w-[400px] min-h-[566px]"></div>
                        )}
                      />
                    )}
                  </div>
                  <div className="min-w-[400px]" onClick={handleGoNext}>
                    <Page
                      pageNumber={pageNumber + 1}
                      width={400}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      loading={() => (
                        <div className="bg-[#162136] min-w-[400px] min-h-[566px]"></div>
                      )}
                    />
                  </div>
                </div>
              </Document>
            )}
            {handle.active && (
              <Document
                onError={console.log}
                file={pdfLink}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <div className="flex">
                  <div className="min-w-[600px]" onClick={handleGoPrev}>
                    {pageNumber === 0 ? (
                      <div className="bg-[#162136] w-[600px] h-[850px]"></div>
                    ) : (
                      <Page
                        pageNumber={pageNumber}
                        width={600}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        loading={() => (
                          <div className="bg-[#162136] min-w-[600px] min-h-[850px]"></div>
                        )}
                      />
                    )}
                  </div>
                  <div className="min-w-[600px]" onClick={handleGoNext}>
                    <Page
                      pageNumber={pageNumber + 1}
                      width={600}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      loading={() => (
                        <div className="bg-[#162136] min-w-[600px] min-h-[850px]"></div>
                      )}
                    />
                  </div>
                </div>
              </Document>
            )}
            <div onClick={handleGoNext} className="rotate-180">
              <Arrow />
            </div>
          </div>
        </FullScreen>
        <button
          onClick={handle.enter}
          className="text-brand-beige-300 font-martel"
        >
          Ver más
        </button>
      </div>
    </div>
  );
}

export default dynamic(() => Promise.resolve(Flipbook), {
  ssr: false
});
